import ApiService from './ApiService'
import axios from 'axios'

export async function apiSignIn(data) {
    return {
        data: {
            user: {
                id: '21',
                avatar: '/img/avatars/thumb-1.jpg',
                userName: 'leet-user',
                email: 'leet-user@leetcarbon.space',
                authority: ['user'],
                password: 'Leet@dev-demo54138924',
                accountUserName: 'leet-user@leetcarbon.space',
            },
            token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
        }
    }
    // return ApiService.fetchData({
    //     url: '/sign-in',
    //     method: 'post',
    //     data,
    // })
}

export async function apiSignUp(data) {
    console.log(data);
    const { userName, password, email } = data;

    const regUser = axios.post(`https://leetintelligence.com:3013/daas/v1/register/daas`, {
        data: {
            userName,
            email,
            password,
        },
    })
        .then(res => {
        }).catch((errors) => {
            console.log(errors);
        });

    return {
        data: {
            user: {
                id: '21',
                avatar: '/img/avatars/thumb-1.jpg',
                userName: userName,
                email: email,
                authority: ['user'],
                password: 'Leet@dev-demo54138924',
                accountUserName: email,
            },
            token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
        }
    }
    // return ApiService.fetchData({
    //     url: '/sign-up',
    //     method: 'post',
    //     data,
    // })
}

export async function apiSignOut(data) {
    return true
    // return ApiService.fetchData({
    //     url: '/sign-out',
    //     method: 'post',
    //     data,
    // })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
